<template>
  <div class="rules">
    <back></back>
    <div class="m-b-20 bold">
      【活动说明】
    </div>
    <div class="m-b-10">
      本次预约抽签活动将抽取299个【姜小竹数字艾灸盒】数字藏品0元购买权，用户需要通过预约抽签参与活动。
    </div>
    <div class="m-b-10">
      参与活动后，用户可通过点亮元气的方式提高中签概率，但在正式发售后才能得知具体中签结果。
    </div>
    <div class="m-b-25">
      中签用户可获得【姜小竹数字艾灸盒】在发售页面，以0元价格购买获得【姜小竹数字艾灸盒】数字藏品。
    </div>
    <div class="m-b-20 bold">
      【活动时间】
    </div>
    <div class="m-b-10">
      {{time1}}--{{time2}} 用户可参与预约点击抢购，获得后续抢购资格；
    </div>
    <div class="m-b-25">
      {{time4}} 正式开启发售，参与活动用户需要再次回到页面参与抢购；
    </div>
    <div class="m-b-20 bold">
      【抽签规则】
    </div>
    <div class="m-b-10">
      点击页面内按钮，即可成功参与抽签
      点亮元气越多，中签概率越高；
    </div>
    <div class="btn f-19 text-center bold" @click="back">
      <span class="text">
        返回
      </span>
    </div>
  </div>
</template>

<script>
import Back from '../components/Back'
import moment from 'moment'
import { getWxConfig } from '../../tool'
export default {
  name: 'Rules',
  components: { Back },
  data () {
    return {
      time1: '',
      time2: '',
      time4: ''
    }
  },
  created () {
    this.time1 = moment(new Date(Number(localStorage.getItem('time1')))).format('YYYY/MM/DD HH:mm')
    this.time2 = moment(new Date(Number(localStorage.getItem('time2')))).format('YYYY/MM/DD HH:mm')
    this.time4 = moment(new Date(Number(localStorage.getItem('time4')))).format('YYYY/MM/DD HH:mm')
    getWxConfig('轻松小镇', '预约规则')
  },
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.rules{
  background: url("../assets/img/rules-bg.png") no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 70px 31px 33px;
  font-size: 13px;
  position: relative;
  .btn{
    position: relative;
    width: 190px;
    padding: 10px 0;
    background: rgba(255, 255, 255, 0.22);
    background-image: linear-gradient(173deg, rgba(255, 255, 255, 0.86), rgba(249, 246, 247, 0.86), rgba(255, 255, 255, 0.86));
    box-shadow: 0px 8px 20px 1px rgba(96, 190, 246, 0.2);
    border-radius: 25px;
    margin: 0 auto;
    margin-top: 60px;
    &:after{
      content: '';
      position: absolute;
      width: 188px;
      height: calc(100% - 2px);
      left: 2px;
      top: 1px;
      background-color: rgba(96, 190, 246, 0.2);
      border-radius: 24px;
    }
  }
}
</style>
